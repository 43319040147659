import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import typeStyle from '@/styles/js/fonts/typeStyles';

/**
 * Get Heading size styles
 * @param {String} size - `m | l | xl`
 */
const getSizeStyles = ({ $size }) =>
  ({
    m: css`
      ${typeStyle('h2')}
    `,
    l: css`
      ${typeStyle('h1')}
    `,
    xl: css`
      ${typeStyle('mega')}
    `,
  }[$size]);

/**
 * Get Heading alignment styles
 * @param {String} alignment - `center`
 */
const getAlignmentStyles = ({ $alignment }) =>
  ({
    center: css`
      text-align: center;
    `,
  }[$alignment]);

/**
 * Get Heading color styles
 * @param {String} color - `alt | altB | reverse`
 */
const getColorStyles = ({ theme, $color }) =>
  ({
    alt: css`
      ${theme.colors.primary};
    `,
    altB: css`
      color: ${theme.colors.accent};
    `,
    reverse: css`
      color: white;
    `,
  }[$color]);

/**
 * Styled Heading
 * ---
 * Implements Heading styles on specified html element.
 */
export const StyledHeading = styled.h3`
  ${typeStyle('h4')}

  color: ${({ theme }) => theme.colors.text};

  > strong {
    border-bottom: 0.11em solid ${({ theme }) => theme.colors.primary};
  }

  > em {
    font-style: normal;
    color: ${({ theme }) => theme.colors.primary};
  }

  ${getSizeStyles}
  ${getColorStyles}
  ${getAlignmentStyles}
`;

/**
 * Heading
 * ---
 * A text heading before a block of content.
 *
 * Optional props
 * - `el`
 *    - `h1 | h2 | h3 (default) | h4 | h5 | h6`
 * - `size`
 *    - `m`
 *    - `l`
 *    - `xl`
 * - `color`
 *    - `alt` - Alternative color
 *    - `altB` - Alternative color B
 *    - `reverse` - For use on dark backgrounds
 * - `alignment`
 *    - `center`  - Centers text
 */
const Heading = ({ el = 'h3', size, color, alignment, ...otherProps }) => (
  <StyledHeading
    as={el}
    $size={size}
    $color={color}
    $alignment={alignment}
    {...otherProps}
  />
);

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  el: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  size: PropTypes.oneOf(['m', 'l', 'xl']),
  color: PropTypes.oneOf(['alt', 'altB', 'reverse']),
  alignment: PropTypes.oneOf(['center']),
};

export default Heading;
