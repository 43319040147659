import caratDownSVG from '@svgs/icons/carat-down-24.svg?url';
import PropTypes from 'prop-types';
import { memo } from 'react';
import styled, { css } from 'styled-components';

import { commonFocus } from '@/styles/js/utils/interations';
import { formSelectStyles } from '@/styles/js/utils/richTextstyles';

/**
 * Styled FormSelect
 * ---
 * option props
 * - `narrow` - Adjust display for especially narrow occurences.
 */
export const StyledFormSelect = styled.select`
  ${formSelectStyles}

  width: 100%;
  background-image: url('${caratDownSVG.src}');
  background-repeat: no-repeat;
  background-position: 96% center;

  &:focus {
    ${commonFocus}
  }

  &[disabled] {
    color: ${({ theme }) => theme.colors.textLight};
    border-color: ${({ theme }) => theme.colors.ruleMedium};
  }

  ${({ $narrow }) =>
    $narrow &&
    css`
      background-position: 90% center;
    `}
`;

/**
 * FormSelect
 * ---
 * Allows the selection of multiple options and
 * implements a <Select> internally.
 *
 * Props
 * - `name`
 * - `emptyOption`
 * - `emptyOptionValue`
 * - `options` - `[{ label: String, value: String | Number }]`
 * - `disabled`
 * - `onChange`
 * - `onBlur`
 * - `value`
 * - `narrow`
 */
const FormSelect = ({
  onChange = () => {},
  emptyOptionValue = '',
  options = [],
  name,
  emptyOption,
  disabled,
  onBlur,
  value,
  narrow,
}) => {
  const handleChange = (evt) => {
    onChange(evt.target.value);
  };

  const renderOptions = () => {
    const updatedOptions = emptyOption
      ? [
          {
            label: emptyOption,
            value: emptyOptionValue,
          },
        ].concat(options)
      : options;

    return updatedOptions.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));
  };

  return (
    <StyledFormSelect
      name={name}
      onChange={handleChange}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
      $narrow={narrow}
    >
      {renderOptions()}
    </StyledFormSelect>
  );
};

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  emptyOption: PropTypes.string,
  emptyOptionValue: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string.isRequired,
  narrow: PropTypes.bool,
};

export default memo(FormSelect);
