import PropTypes from 'prop-types';
import { memo } from 'react';
import styled, { css } from 'styled-components';

import { formText } from '@/styles/js/utils/richTextstyles';

/**
 * Styled FormText
 * ---
 */
export const StyledFormText = styled.input`
  ${formText}

  ${({ theme, $isError }) =>
    $isError &&
    css`
      border-bottom-color: ${theme.colors.red};
    `}
`;

/**
 * A basic text input.
 */
const FormText = ({
  placeholder = '',
  value = '',
  onChange = () => {},
  autocomplete = false,
  type = 'text',
  disabled = false,
  name,
  error,
  ...inputProps
}) => (
  <StyledFormText
    {...inputProps}
    $isError={error}
    name={name}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    id={name}
    type={type}
    autoComplete={autocomplete ? 'on' : 'off'}
    autoCorrect="off"
    disabled={disabled}
  />
);

FormText.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  autocomplete: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password', 'tel']),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default memo(FormText);
